<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8 h-8">
      <h2 class="text-lg font-medium mr-auto">會員管理</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div
          v-show="!isEditing && isRowSelected"
          id="batch-dropdown"
          class="dropdown ml-auto mr-2 sm:ml-0"
        >
          <button
            class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center"
          >
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" />
                刪除
              </span>
              <!-- <span 
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="onPrint('selected')"
              >
                <FontAwesome icon="print" type="fas" class="w-4 h-4 mr-2" /> 列印
              </span> -->
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="onExport('csv', 'selected')"
              >
                <FontAwesome icon="file-csv" type="fas" class="w-4 h-4 mr-2" />
                匯出 CSV
              </span>
              <!-- <span 
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="onExport('csv', 'selected')"
              >
                <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> 匯出 HTML
              </span> -->
            </div>
          </div>
        </div>
        <button
          v-show="!isEditing"
          class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="addNewRow"
        >
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增會員
        </button>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <form class="xl:flex sm:mr-auto" @submit.prevent="onFilter">
          <div class="sm:flex items-center sm:mr-2 mt-2 xl:mt-0">
            <select
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              v-model="selectdCity"
              @change="grid.commitProxy('reload')"
            >
              <option value="">請選擇縣市</option>
              <option
                v-for="(option, index) in form.CityList"
                :key="index"
                :value="option.value"
              >
                {{ option.label }}
              </option>
            </select>
          </div>
          <div class="sm:flex items-center sm:mr-2 mt-2 xl:mt-0">
            <select
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              v-model="selectdGuild"
              @change="grid.commitProxy('reload')"
            >
              <option value="0">請選擇公會名稱</option>
              <option
                v-for="(option, index) in form.CategoryList"
                :key="index"
                :value="option.value"
              >
                {{ option.label }}
              </option>
            </select>
          </div>
          <div class="sm:flex items-center sm:mr-2 mt-2 xl:mt-0">
            <input
              type="text"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              v-model="keyword"
              placeholder="請輸入關鍵字"
              @keyup.enter="grid.commitProxy('reload')"
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
              @click="grid.commitProxy('reload')"
            >
              搜尋
            </button>
          </div>
        </form>
        <div class="flex mt-5 sm:mt-0">
          <!-- <button
            class="button w-1/2 sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            @click="onPrint('curret')"
          >
            <FontAwesome icon="print" class="w-4 h-4 mr-2" /> 列印
          </button> -->
          <div class="dropdown w-1/2 sm:w-auto ml-2">
            <button
              class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            >
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExport('csv')"
                >
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a>
                <!-- <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExport('html')"
                >
                  <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> HTML
                </a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <vxe-grid
          id="grid"
          ref="grid"
          class="mt-5 table-report"
          v-bind="gridOptions"
        >
          <template #membershipStatusOption="{ row }">
            <vxe-checkbox
              v-model="row.MembershipStatus"
              @change="changeMembershipStatus(row)"
            ></vxe-checkbox>
          </template>
          <template #paymentOption="{ row }">
            <vxe-checkbox
              v-model="row.Payment"
              @change="changePayment(row)"
            ></vxe-checkbox>
          </template>
          <template #operate="{ row }">
            <slot name="operate" :row="row">
              <div class="flex" v-if="!isEditing">
                <button
                  class="button rounded-full mr-1 mb-2 bg-theme-1 text-white"
                  title="編輯"
                  @click="edit(row)"
                >
                  <FontAwesome icon="highlighter" class="w-4 h-4" />
                </button>
                <button
                  class="button rounded-full mr-1 mb-2 bg-theme-6 text-white"
                  title="取消"
                  @click="remove(row)"
                >
                  <FontAwesome icon="trash" class="w-4 h-4" />
                </button>
              </div>
            </slot>
          </template>
          <template #name_filter="{ column, $panel }">
            <div
              v-for="(option, index) in column.filters"
              :key="index"
              class="flex item-center justify-center"
            >
              <input
                type="checkbox"
                class="input input--switch border mt-4 ml-2"
                :checked="option.checked"
                @input="changeFilterEvent(event, option, $panel, true)"
              />
              <div class="relative m-2">
                <div
                  class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600"
                >
                  {{ option.label }}
                </div>
                <input
                  v-model.trim="option.data"
                  :disabled="!option.checked"
                  type="text"
                  class="input pl-12 w-full border col-span-4"
                  placeholder="請輸入文字"
                  @input="changeFilterEvent(event, option, $panel)"
                />
              </div>
            </div>
          </template>
        </vxe-grid>
      </div>
    </div>

    <vxe-modal
      v-model="form.showEdit"
      :title="form.model == 'edit' ? '編輯會員' : '新增會員'"
      show-zoom="true"
      lock-scroll="false"
      width="1000"
      min-width="600"
      min-height="300"
      resize
      destroy-on-close
    >
      <template #default>
        <vxe-form
          :data="form.formData"
          :items="form.formItems"
          :rules="form.formRules"
          title-align="right"
          title-width="100"
          @submit="submitSave"
        ></vxe-form>
      </template>
    </vxe-modal>

    <!-- END: HTML Table Data -->
  </div>
</template>

<script lang="ts">
/* eslint-disable */

import { onMounted } from "vue";
import CloudFun, {
  defineComponent,
  ref,
  reactive,
  computed,
  Sorting,
  SortOrder,
  Condition,
  Operator,
  LogicalConnective,
  Model,
} from "@cloudfun/core";
import { method } from "node_modules/@types/lodash";
import {
  VxeColumnPropTypes,
  VxeGridInstance,
  VxeGridProps,
  VxeFormPropTypes,
  Column,
} from "vxe-table";
import { cityList, districtList, GetZip } from "@/libs/city";

export default defineComponent({
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref({} as VxeGridInstance);
    const loading = ref(false);
    const editingRow = ref(null);
    const keyword = ref("");
    const selectdGuild = ref(0);
    const selectdCity = ref("");
    const customFilters = ref<Condition[]>([]);
    const isRowSelected = computed(
      () =>
        (grid.value.getRadioRecord && grid.value.getRadioRecord()) ||
        (grid.value.getCheckboxRecords &&
          grid.value.getCheckboxRecords().length > 0)
    );
    const isEditing = computed(
      () =>
        editingRow.value != null && grid.value.isActiveByRow(editingRow.value)
    );
    var printColumns = [
      { field: "MemberNumber" },
      { field: "OrganizationString" },
      { field: "CompanyName" },
      { field: "StationName" },
      { field: "Principal" },
      { field: "Representative" },
      { field: "MembershipStatusString" },
      { field: "Phone1" },
      { field: "Phone2" },
      { field: "Profile.Fax" },
      // { field: 'Profile.Address.Full' },
      { field: "Profile.Address.PostalCode" },
      { field: "Profile.Address.City" },
      { field: "Profile.Address.District" },
      { field: "Profile.Address.Line" },
      { field: "PaymentString" },
      { field: "PaymentDateString" },
    ];

    const gridOptions = reactive({
      keepSource: true,
      round: true,
      border: true,
      stripe: true,
      highlightHoverColumn: true,
      highlightHoverRow: true,
      resizable: true,
      loading: false,
      customConfig: { storage: true },
      filterConfig: { remote: true },
      sortConfig: { remote: true, multiple: true },
      //editConfig: { trigger: 'manual', mode: 'row', showIcon: false, autoClear: false },
      pagerConfig: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [5, 10, 20],
        layouts: [
          "PrevJump",
          "PrevPage",
          "Jump",
          "PageCount",
          "NextPage",
          "NextJump",
          "Sizes",
          "Total",
        ],
      },
      printConfig: {
        sheetName: "會員清單",
        columns: printColumns,
        modes: ["current", "selected", "all"],
      },
      exportConfig: {
        filename: "會員清單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns,
      },
      columns: [
        { type: "checkbox", width: 35, fixed: "left", resizable: false },
        {
          field: "MemberNumber",
          title: "會員編號",
          showHeaderOverflow: true,
          showOverflow: true,
          resizable: false,
        },
        {
          field: "MembershipStatusString",
          title: "會籍狀態",
          showHeaderOverflow: true,
          showOverflow: true,
          resizable: false,
          visible: false,
        },
        {
          field: "Profile.Address.PostalCode",
          title: "郵遞區號",
          showHeaderOverflow: true,
          showOverflow: true,
          resizable: false,
          visible: false,
        },
        {
          field: "Profile.Address.City",
          title: "縣市",
          showHeaderOverflow: true,
          showOverflow: true,
          resizable: false,
          visible: false,
        },
        {
          field: "Profile.Address.District",
          title: "行政區",
          showHeaderOverflow: true,
          showOverflow: true,
          resizable: false,
          visible: false,
        },
        {
          field: "Profile.Address.Line",
          title: "地址",
          showHeaderOverflow: true,
          showOverflow: true,
          resizable: false,
          visible: false,
        },
        {
          field: "Phone2",
          title: "聯絡電話2",
          showHeaderOverflow: true,
          showOverflow: true,
          resizable: false,
          visible: false,
        },
        {
          field: "Profile.Fax",
          title: "傳真",
          showHeaderOverflow: true,
          showOverflow: true,
          resizable: false,
          visible: false,
        },
        {
          field: "PaymentString",
          title: "繳費狀態",
          showHeaderOverflow: true,
          showOverflow: true,
          resizable: false,
          visible: false,
        },
        {
          field: "PaymentDateString",
          title: "最後繳費日期",
          showHeaderOverflow: true,
          showOverflow: true,
          resizable: false,
          visible: false,
        },
        {
          field: "Representative",
          title: "代表人",
          showHeaderOverflow: true,
          showOverflow: true,
          resizable: false,
          visible: false,
        },
        {
          field: "OrganizationString",
          title: "所屬工會",
          showHeaderOverflow: true,
          showOverflow: true,
          resizable: false,
        },
        {
          field: "CompanyName",
          title: "公司名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          resizable: false,
          width: 230,
        },
        {
          field: "StationName",
          title: "站名",
          showHeaderOverflow: true,
          showOverflow: true,
          resizable: false,
        },
        {
          field: "Principal",
          title: "負責人",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          filters: [{ checked: false, label: "包含" }],
          slots: { filter: "name_filter" },
        },
        {
          field: "Representative",
          title: "會員代表",
          showHeaderOverflow: true,
          showOverflow: true,
          resizable: false,
        },
        {
          field: "Phone1",
          title: "聯絡電話",
          showHeaderOverflow: true,
          showOverflow: true,
          resizable: false,
        },
        {
          field: "Profile.Address.City",
          title: "縣市",
          showHeaderOverflow: true,
          showOverflow: true,
          resizable: false,
        },
        {
          title: "會籍狀態",
          width: 100,
          fixed: "right",
          align: "center",
          resizable: false,
          slots: { default: "membershipStatusOption" },
        },
        {
          title: "繳費狀態",
          width: 100,
          fixed: "right",
          align: "center",
          resizable: false,
          slots: { default: "paymentOption" },
        },
        // { field: "Profile.Address.Full", title: "完整地址", showHeaderOverflow: true, showOverflow: true, resizable: false, visible: true, width:300 },
        {
          title: "操作",
          width: 100,
          fixed: "right",
          align: "center",
          resizable: false,
          slots: { default: "operate" },
        },
      ],
      proxyConfig: {
        message: false,
        sort: true,
        filter: true,
        props: { result: "data", total: "totalCount" },
        ajax: {
          query: (params) => {
            //params.sorts.push({ column: Column.prototype, order: 'desc', sortBy: 'Number', property:'Number' });

            const queryParams: {
              page: number;
              pageSize: number;
              keyword: string;
              city: string;
              guildId: number;
              sortings?: Sorting[];
              condition: Condition;
            } = {
              page: params.page.currentPage,
              pageSize: params.page.pageSize,
              keyword: keyword.value,
              city: selectdCity.value,
              guildId: selectdGuild.value,
              sortings: params.sorts
                .filter((e) => e.property)
                .map(
                  (e) =>
                    new Sorting(
                      e.property,
                      e.order === "desc"
                        ? SortOrder.Descending
                        : SortOrder.Ascending
                    )
                ), // sorts
              condition: new Condition(customFilters.value), // custom filters
            };

            // filters
            const filters = params.filters
              ? params.filters.filter((e) => e.values.length)
              : null;
            if (filters && filters.length) {
              const gridCondition = new Condition();
              // colum filters
              filters.forEach((filter) => {
                const columnCondition = new Condition();
                filter.values.forEach((subFilter) => {
                  if (subFilter && subFilter instanceof Condition) {
                    const condition = subFilter as Condition;
                    if (condition.connective === LogicalConnective.And)
                      columnCondition.and(condition);
                    else columnCondition.or(condition);
                  }
                });
                gridCondition.and(columnCondition);
              });
              queryParams.condition.and(gridCondition);
            }
            return new Promise((resolve, reject) =>
              model?.dispatch("member/load", queryParams).then(
                (payload) => {
                  console.log("promise", payload);
                  resolve(payload);
                },
                (reason) => {
                  CloudFun.send("error", {
                    subject: "讀取失敗",
                    content: reason,
                  });
                  reject(reason);
                }
              )
            );
          },
          delete: (params) => {
            const deleteParams = { deleteRows: params.body.removeRecords };
            return new Promise((resolve, reject) =>
              model?.dispatch("member/save", deleteParams).then(
                (payload) => resolve(payload),
                (reason) => {
                  CloudFun.send("error", {
                    subject: "刪除失敗",
                    content: reason,
                  });
                  reject(reason);
                }
              )
            );
          },
          save: (params) => {
            const saveParams = {
              insertRows: params.body.insertRecords,
              updateRows: params.body.updateRecords,
              deleteRows: params.body.removeRecords,
            };
            return new Promise((resolve, reject) =>
              model?.dispatch("member/save", saveParams).then(
                (payload) => {
                  editingRow.value = null;
                  resolve(payload);
                },
                (reason) => {
                  CloudFun.send("error", {
                    subject: "保存失敗",
                    content: reason,
                  });
                  reject(reason);
                }
              )
            );
          },
        },
      },
    } as VxeGridProps);

    //modal 執行 新增or修改
    const submitSave = () => {
      var method = form.model == "insert" ? "insert" : "update";
      form.formData.Profile.Address.PostalCode = GetZip(
        form.formData.Profile.Address.City,
        form.formData.Profile.Address.District
      );
      gridOptions.loading = true;
      model
        ?.dispatch(`member/${method}`, form.formData)
        .then(
          (paylod) => {
            //var sorting = new Sorting('CreatedTime', 1);
            grid.value.commitProxy("query").finally(() => {});
            CloudFun.send("info", {
              subject: method == "insert" ? "新增成功" : "更新成功",
              content: method == "insert" ? "會員新增完成" : "會員更新完成",
            });
          },
          (failure) =>
            CloudFun.send("error", { subject: "操作失敗！", content: failure })
        )
        .finally(() => {
          form.showEdit = false;
          gridOptions.loading = false;
        });
    };

    const changeCity = () => {
      form.DistrictsList = districtList(form.formData.Profile.Address.City);
      var districtOption = form.formItems[12];
      if (districtOption.itemRender) {
        districtOption.itemRender.options = form.DistrictsList;
        form.formData.Profile.Address.District = form.DistrictsList[0]["value"];
        //console.log(GetZip(form.formData.Profile.Address.City, form.formData.Profile.Address.District))
      }
    };

    // const disabledInit = () =>{
    //   var disabledOption = form.formItems[0];
    //   if (disabledOption.itemRender) {
    //     var result = form.model == 'edit' ? true : false;
    //     console.log(result, disabledOption.itemRender.props)
    //     disabledOption.itemRender.props = { placeholder: '編號', disabled: result };
    //   }
    // };

    const form = reactive({
      model: "insert",
      CityList: [],
      DistrictsList: [],
      CategoryList: [],
      selectRow: true,
      showEdit: false,
      formData: {
        Id: "00000000-0000-0000-0000-000000000000",
        CreatedTimeString: "",
        CreatedTime: "",
        MemberNumber: "",
        CompanyName: "",
        StationName: "",
        MembershipStatus: 0,
        Payment: false,
        OrganizationId: 0,
        Principal: "",
        Representative: "",
        Phone1: "",
        Phone2: "",
        Remark: "",
        Profile: {
          Id: 0,
          Fax: "",
          Address: {
            Id: 0,
            City: "",
            District: "",
            Line: "",
            PostalCode: "",
          },
        },
      },
      formRules: {
        Principal: [{ required: true, message: "請輸入負責人" }],
        // MemberNumber: [
        //   { required: true, message: '請輸入會員編號(整數數字)', pattern: new RegExp("^[0-9\\ ]+$") }
        // ],
        "Profile.Address.City": [{ required: true, message: "請輸入縣市" }],
        "Profile.Address.District": [
          { required: true, message: "請輸入行政區" },
        ],
        "Profile.Address.Line": [{ required: true, message: "請輸入詳細地址" }],
        MembershipStatus: [{ required: true, message: "請輸入狀態" }],
        OrganizationId: [{ required: true, message: "請輸入同業公會" }],
        Phone1: [
          {
            message: "無效的電話號碼",
            pattern: new RegExp(
              "^([\\+][0-9]{1,3}([ \\.\\-])?)?([\\(][0-9]{1,6}[\\)])?([0-9 \\.\\-|#]{1,32})(([A-Za-z \\:]{1,11})?[0-9]{1,4}?)$"
            ),
          },
        ],
        Phone2: [
          {
            message: "無效的電話號碼",
            pattern: new RegExp(
              "^([\\+][0-9]{1,3}([ \\.\\-])?)?([\(][0-9]{1,6}[\\)])?([0-9 \\.\\-|#]{1,32})(([A-Za-z \\:]{1,11})?[0-9]{1,4}?)$"
            ),
          },
        ],
        "Profile.Fax": [
          {
            message: "無效的傳真號碼",
            pattern: new RegExp(
              "^([\\+][0-9]{1,3}([ \\.\\-])?)?([\\(][0-9]{1,6}[\\)])?([0-9 \\.\\-|#]{1,32})(([A-Za-z \\:]{1,11})?[0-9]{1,4}?)$"
            ),
          },
        ],
      } as VxeFormPropTypes.Rules,
      formItems: [
        {
          field: "MemberNumber",
          title: "會員編號",
          span: 12,
          itemRender: {
            name: "$input",
            props: { placeholder: "系統自動產生", readonly: false },
          },
        },
        {
          field: "OrganizationId",
          title: "同業公會",
          span: 12,
          itemRender: { name: "$select", options: [] },
        },
        {
          field: "CompanyName",
          title: "公司名稱",
          span: 12,
          itemRender: { name: "$input", props: { placeholder: "公會名稱" } },
        },
        {
          field: "StationName",
          title: "站名",
          span: 12,
          itemRender: { name: "$input", props: { placeholder: "站名" } },
        },
        {
          field: "MembershipStatus",
          title: "會籍狀態",
          span: 12,
          itemRender: {
            name: "$radio",
            options: [
              { label: "已入會", value: 1 },
              { label: "未入會", value: 0 },
            ],
          },
        },
        {
          field: "Payment",
          title: "繳費狀態",
          span: 12,
          itemRender: {
            name: "$radio",
            options: [
              { label: "已繳費", value: true },
              { label: "未繳費", value: false },
            ],
          },
        },
        {
          field: "Principal",
          title: "負責人",
          span: 12,
          itemRender: { name: "$input", props: { placeholder: "負責人" } },
        },
        {
          field: "Representative",
          title: "會員代表",
          span: 12,
          itemRender: { name: "$input", props: { placeholder: "會員代表" } },
        },
        {
          field: "Phone1",
          title: "聯絡電話1",
          span: 12,
          itemRender: { name: "$input", props: { placeholder: "聯絡電話1" } },
        },
        {
          field: "Phone2",
          title: "聯絡電話2",
          span: 12,
          itemRender: { name: "$input", props: { placeholder: "聯絡電話2" } },
        },
        {
          field: "Profile.Fax",
          title: "傳真",
          span: 12,
          itemRender: { name: "$input", props: { placeholder: "傳真" } },
        },
        {
          field: "Profile.Address.City",
          title: "縣市",
          span: 6,
          itemRender: {
            name: "$select",
            options: [],
            events: { change: changeCity },
          },
        },
        {
          field: "Profile.Address.District",
          title: "行政區",
          span: 6,
          itemRender: { name: "$select", options: [] },
        },
        {
          field: "Profile.Address.Line",
          title: "詳細地址",
          span: 24,
          itemRender: { name: "$input", props: { placeholder: "詳細地址" } },
        },
        {
          field: "Remark",
          title: "備註",
          span: 24,
          itemRender: {
            name: "$textarea",
            props: {
              autosize: { minRows: 2, maxRows: 4 },
              placeholder: "內容",
            },
          },
        },
        {
          align: "right",
          span: 24,
          titleAlign: "left",
          itemRender: {
            name: "$buttons",
            children: [
              { props: { type: "submit", content: "確認", status: "primary" } },
              { props: { type: "reset", content: "重置" } },
            ],
          },
        }, //, events: { click: save }
      ] as VxeFormPropTypes.Items,
    });

    const cityInit = () => {
      form.DistrictsList = districtList("台北市");
      var districtOption = form.formItems[12];
      if (districtOption.itemRender) {
        (form.formData.Profile.Address.City = form.CityList[0]["value"]),
          (districtOption.itemRender.options = form.DistrictsList);
        form.formData.Profile.Address.District = form.DistrictsList[0]["value"];
      }
    };

    const baseUrl = `${process.env.VUE_APP_BACKEND_URL}`;
    const getCategories = () => {
      return new Promise((resolve, reject) => {
        Model.createHttpClient(baseUrl)
          .get(`${baseUrl}/api/Organization`, {
            params: {
              keyword: null,
              orderColumn: "CreatedTime",
              descending: true,
              skipRows: null,
              takeRows: null,
              simple: true,
              filter: true,
            },
          })
          .then(function (response: any) {
            resolve(response.payload);
          })
          .catch(function (error) {
            reject(error);
            //console.log(error.response)
          });
      });
    };

    onMounted(() => {
      console.log("onMounted");
      form.CityList = cityList();
      var citytOption = form.formItems[11];
      if (citytOption.itemRender)
        citytOption.itemRender.options = form.CityList;
      cityInit();

      getCategories().then((data: any) => {
        var item = form.formItems[1];
        if (item.itemRender && data.length > 0) {
          form.CategoryList = data;
          item.itemRender.options = data;
          //console.log(item.itemRender.options, form.CategoryList[0]['value'])
        }
      });
    });

    return {
      selectdGuild,
      selectdCity,
      form,
      grid,
      gridOptions,
      loading,
      isEditing,
      editingRow,
      isRowSelected,
      keyword,
      customFilters,
      submitSave,
      cityInit,
      changeCity,
    };
  },
  methods: {
    reset() {
      //console.log(this.form.CityList[0]['value'], this.form.DistrictsList[0]['value'])
      this.form.formData.Id = "00000000-0000-0000-0000-000000000000";
      this.form.formData.MembershipStatus = 0;
      this.form.formData.CreatedTimeString = "";
      this.form.formData.CreatedTime = "";
      this.form.formData.OrganizationId =
        this.form.CategoryList.length > 0
          ? this.form.CategoryList[0]["value"]
          : 0;
      this.form.formData.Principal = "";
      this.form.formData.Representative = "";
      this.form.formData.MemberNumber = "";
      this.form.formData.CompanyName = "";
      this.form.formData.StationName = "";
      this.form.formData.Phone1 = "";
      this.form.formData.Phone2 = "";
      this.form.formData.Remark = "";
      this.form.formData.Profile.Id = 0;
      this.form.formData.Profile.Fax = "";
      this.form.formData.Profile.Address.Id = 0;
      this.form.formData.Profile.Address.Line = "";
      this.form.formData.Profile.Address.PostalCode = "";
      this.form.formData.Payment = false;
      this.cityInit();
      //this.form.formData.Address.City = this.form.CityList[0]['value'];
      //this.form.formData.Address.District = this.form.DistrictsList[0]['value'];
    },
    edit(row: any) {
      this.$model.dispatch("member/find", row.Id).then(
        (paylod) => {
          Object.assign(this.form.formData, paylod);
          this.dataTrim();
          this.form.model = "edit";
          if (
            this.form.formData.Profile.Address &&
            this.form.formData.Profile.Address.City
          )
            this.defaultDistrict(this.form.formData.Profile.Address.District);
          this.form.showEdit = true;
        },
        (failure) =>
          this.$send("error", {
            subject: "操作失敗！",
            content: failure.message,
          })
      );
    },
    dataTrim() {
      if (this.form.formData.Principal)
        this.form.formData.Principal = this.form.formData.Principal.trim();
      if (this.form.formData.Representative)
        this.form.formData.Representative = this.form.formData.Representative.trim();
      if (this.form.formData.CompanyName)
        this.form.formData.CompanyName = this.form.formData.CompanyName.trim();
      if (this.form.formData.StationName)
        this.form.formData.StationName = this.form.formData.StationName.trim();
      if (this.form.formData.Phone1)
        this.form.formData.Phone1 = this.form.formData.Phone1.trim();
      if (this.form.formData.Phone2)
        this.form.formData.Phone2 = this.form.formData.Phone2.trim();
      if (this.form.formData.Profile.Fax)
        this.form.formData.Profile.Fax = this.form.formData.Profile.Fax.trim();
      if (this.form.formData.Profile.Address.District)
        this.form.formData.Profile.Address.District = this.form.formData.Profile.Address.District.trim();
      if (this.form.formData.Profile.Address.City)
        this.form.formData.Profile.Address.City = this.form.formData.Profile.Address.City.trim();
      if (this.form.formData.Profile.Address.Line)
        this.form.formData.Profile.Address.Line = this.form.formData.Profile.Address.Line.trim();
    },
    addNewRow() {
      this.reset();
      this.form.model = "insert";
      this.form.showEdit = true;
    },
    save(row?: any) {
      const {
        insertRecords,
        updateRecords,
        removeRecords,
      } = this.grid.getRecordset();
      let needSave =
        insertRecords.length || updateRecords.length || removeRecords.length;
      needSave &&=
        row &&
        (insertRecords.some((e) => e._XID === row._XID) ||
          updateRecords.some((e) => e._XID === row._XID) ||
          removeRecords.some((e) => e._XID === row._XID));
      const isRowEditing = row && this.editingRow === row;
      if (needSave) {
        this.grid.commitProxy("save").then(() => {
          if (isRowEditing && this.editingRow === row) this.edit(row);
          else this.editingRow = null;
        });
      } else if (!row || row === this.editingRow) this.editingRow = null;
    },
    remove(row: any) {
      if (row && confirm("確定要刪除此筆資料嗎?")) {
        this.gridOptions.loading = true;
        this.$model.dispatch("member/delete", row.Id).then(
          () => {
            this.grid.commitProxy("query").finally(() => {
              this.gridOptions.loading = false;
            });
          },
          (failure) =>
            this.$send("error", {
              subject: "會員刪除失敗",
              content: failure.message,
            })
        );
      }
    },
    removeSelectedRows() {
      cash("#batch-dropdown").dropdown("hide");
      const rows = this.grid.getCheckboxRecords();
      if (rows && rows.length && confirm("確定要刪除已被選擇的資料嗎?")) {
        this.grid.commitProxy("delete");
      }
    },
    changeFilterEvent(
      event: Event,
      option: VxeColumnPropTypes.Filter,
      panel: any,
      toogleChecked?: boolean
    ) {
      if (toogleChecked) option.checked = !option.checked;
      if (option.checked && option.data) {
        if (!option.value)
          option.value = new Condition("Name", Operator.Contains);
        option.value.value = option.data;
      } else option.value = option.data = undefined;
      if (toogleChecked || option.checked)
        panel.changeOption(event, !!option.data, option);
    },
    defaultDistrict(district: string) {
      this.form.DistrictsList = districtList(
        this.form.formData.Profile.Address.City
      );
      var districtOption = this.form.formItems[12];
      if (districtOption.itemRender) {
        districtOption.itemRender.options = this.form.DistrictsList;
        this.form.formData.Profile.Address.District =
          district ?? this.form.DistrictsList[0]["value"];
      }
    },
    async onExport(
      type: "csv" | "html",
      mode: "current" | "selected" | "all" = "all"
    ) {
      const config: any = { ...this.gridOptions.exportConfig, type, mode };
      if (mode === "all") {
        const queryParams: {
          keyword: string;
          city: string;
          guildId: number;
          sortings?: Sorting[];
        } = {
          keyword: this.keyword,
          city: this.selectdCity,
          guildId: this.selectdGuild,
          sortings: [new Sorting("MemberNumber")], // sorts
        };
        config.data = await this.$model.dispatch("member/query", queryParams);
      }

      this.grid.exportData({ ...config, type, mode });
    },
    async onPrint(mode: "current" | "selected" | "all" = "all") {
      const config: any = { ...this.gridOptions.printConfig, mode };
      if (mode === "all")
        config.data = await this.$model.dispatch("member/query");
      this.grid.print(config);
    },
    changeMembershipStatus(row: any) {
      this.changeStatus(row.Id, "MembershipStatus", row.MembershipStatus);
    },
    changePayment(row: any) {
      this.changeStatus(row.Id, "Payment", row.Payment);
    },
    changeStatus(id: string, name: string, status: any) {
      this.gridOptions.loading = true;
      this.$model
        .dispatch(`member/updateStatus`, { id, name, status })
        .then(
          () => {
            this.grid.commitProxy("query").finally(() => {});
            CloudFun.send("info", {
              subject: "更新成功",
              content: "會員更新完成",
            });
          },
          (failure) =>
            CloudFun.send("error", { subject: "操作失敗！", content: failure })
        )
        .finally(() => {
          this.gridOptions.loading = false;
        });
    },
  },
});
</script>
