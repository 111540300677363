<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <!-- BEGIN: Standard Editor -->
      <div class="col-span-12 lg:col-span-12">
        <div class="box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">組織架構</h2>
            <div
              class="w-full sm:w-auto flex items-center sm:ml-auto mt-3 sm:mt-0"
            >
            </div>
          </div>
          <div id="standard-editor" class="p-5">
            <div class="preview">
              <CKEditor
                v-model="entity.Content"
                :editor="classicEditor"
                :config="editorConfig"
              />
            </div>
            <div class="text-right">
            <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold mt-2 py-2 px-4 rounded" @click="PostObject">儲存</button>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Standard Editor -->
      
    </div>
  </div>
</template>

<style>
  .ck .ck .ck-content {
    min-height: 400px !important;
  }
  /* .ck.ck-button .ck.ck-tooltip {
    display: none;
  } */
</style>

<script lang="ts">
import { defineComponent, onMounted, reactive } from "vue";
import CloudFun, { Model } from "@cloudfun/core";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import "@/global-components/ckeditor/styles.css";

export default defineComponent({
  setup() {
    const model = CloudFun.current?.model;
    const classicEditor = ClassicEditor;
    const editorConfig = {      
      extraPlugins: [
        UploadAdapterPlugin
      ],      
      toolbar: {
        items: [
          'heading',
          '|',
          'fontFamily',
          'fontSize',
          'fontColor',
          'fontBackgroundColor',
          '|',
          'bold',
          'italic',
          'underline',
          'bulletedList',
          'numberedList',
          'outdent',
          'indent',
          'highlight',
          'insertTable',
          '|',
          'link',
          'blockQuote',
          'imageInsert',
          'mediaEmbed',
          // 'codeBlock',
          // 'htmlEmbed',
          '|',
          '|',
          'undo',
          'redo'
        ]
      },
      removePlugins: ['Markdown'],
      image: {
        toolbar: [
          'imageTextAlternative',
          'imageStyle:full',
          'imageStyle:side',
          'linkImage'
        ]
      },
      table: {
        contentToolbar: [
          'tableColumn',
          'tableRow',
          'mergeTableCells',
          'tableCellProperties',
          'tableProperties'
        ]
      },
      heading: {
          options: [
              { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
              { model: 'heading1', view: { name: 'h1', classes: 'font-bold text-xl' }, title: 'Heading 1', class: 'ck-heading_heading1' },
              { model: 'heading2', view: { name: 'h2', classes: 'font-bold text-base' }, title: 'Heading 2', class: 'ck-heading_heading2' }
          ]
      }
    };
    var entity = reactive({});

    const baseUrl = `${process.env.VUE_APP_BACKEND_URL}`;    
    const getAbout = () => {
        return new Promise((resolve, reject) => {
          Model.createHttpClient(baseUrl).get(`${baseUrl}/api/About`, { params: { name: 'About2' } })
          .then(function (response: any) {
            resolve(response.payload)
          })
          .catch(function (error) {
            reject(error)
            console.log(error.response)
          });
      });
    };

    const PostObject = () => {
      const formData = new FormData();
      
      formData.append("json", JSON.stringify(entity));
      model?.dispatch(`about/update`, formData).then(
        paylod => {
          Object.assign(entity, paylod)
          CloudFun.send('info', { subject: '儲存成功', content: '組織架構儲存成功！' })
        },
        failure => CloudFun.send('error', { subject: '儲存失敗', content: failure })
      );
     
    }

    onMounted(() => {
      getAbout().then((data: any) =>{  
        Object.assign(entity, data)
      });
    })
    
    return {
      entity,
      PostObject,
      classicEditor,
      editorConfig,
    };
  },
});
</script>
