
import { defineComponent, onMounted, reactive } from "vue";
import CloudFun, { Model } from "@cloudfun/core";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import ClassicEditor from "@/global-components/ckeditor/classic-editor"
import "@/global-components/ckeditor/styles.css";

export default defineComponent({
  setup() {
    const model = CloudFun.current?.model;
    const classicEditor = ClassicEditor;
    const editorConfig = {      
      extraPlugins: [
        UploadAdapterPlugin
      ],
      toolbar: {
        items: [
          'heading',
          '|',
          'fontFamily',
          'fontSize',
          'fontColor',
          'fontBackgroundColor',
          '|',
          'bold',
          'italic',
          'underline',
          'bulletedList',
          'numberedList',
          'outdent',
          'indent',
          'highlight',
          'insertTable',
          '|',
          'link',
          'blockQuote',
          'imageInsert',
          'mediaEmbed',
          // 'codeBlock',
          // 'htmlEmbed',
          '|',
          '|',
          'undo',
          'redo'
        ]
      },
      removePlugins: ['Markdown'],
      image: {
        toolbar: [
          'imageTextAlternative',
          'imageStyle:full',
          'imageStyle:side',
          'linkImage'
        ]
      },
      table: {
        contentToolbar: [
          'tableColumn',
          'tableRow',
          'mergeTableCells',
          'tableCellProperties',
          'tableProperties'
        ]
      },
      heading: {
          options: [
              { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
              { model: 'heading1', view: { name: 'h1', classes: 'font-bold text-xl' }, title: 'Heading 1', class: 'ck-heading_heading1' },
              { model: 'heading2', view: { name: 'h2', classes: 'font-bold text-base' }, title: 'Heading 2', class: 'ck-heading_heading2' }
          ]
      }
    };
    var entity = reactive({});

    const baseUrl = `${process.env.VUE_APP_BACKEND_URL}`;    
    const getAbout = () => {
      return new Promise((resolve, reject) => {
          Model.createHttpClient(baseUrl).get(`${baseUrl}/api/About`, { params: { name: 'About1' } })
          .then(function (response: any) {
            resolve(response.payload)
          })
          .catch(function (error) {
            reject(error)
            console.log(error.response)
          });
      });
    };

    const PostObject = () => {
      const formData = new FormData();      
      formData.append("json", JSON.stringify(entity));
      model?.dispatch(`about/update`, formData).then(
        paylod => {
          Object.assign(entity, paylod)
          CloudFun.send('info', { subject: '儲存成功', content: '成立宗旨及任務儲存成功！' })
        },
        failure => CloudFun.send('error', { subject: '儲存失敗', content: failure })
      );      
    }

    onMounted(() => {
      getAbout().then((data: any) =>{  
        Object.assign(entity, data)
      });
    })
    
    return {
      entity,
      PostObject,
      classicEditor,
      editorConfig,
    };
  },
});
