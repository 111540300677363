
/* eslint-disable */

import { onMounted } from "vue";
import CloudFun, { Model, defineComponent, ref, reactive, computed, Sorting, SortOrder, Condition, Operator, LogicalConnective } from "@cloudfun/core";
import { VxeColumnPropTypes, VxeGridInstance, VxeGridProps, VxeFormPropTypes, VxeModalInstance } from "vxe-table";
import FileUpload from 'vue-upload-component';
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import ClassicEditor from "@/global-components/ckeditor/classic-editor"
import "@/global-components/ckeditor/styles.css";

export default defineComponent({
  components:{    
    FileUpload,    
  },
  setup() {
    const uploader = ref({});
    const files = ref([]);
    const AttachmentUploader = ref({});
    const AttachmentFiles = ref([]);
    const modal = ref({} as VxeModalInstance);
    const model = CloudFun.current?.model;
    const grid = ref({} as VxeGridInstance);    
    const loading = ref(false);
    const editingRow = ref(null);
    const keyword = ref("");  
    const oriUri = ref("");
    const customFilters = ref<Condition[]>([]);
    const isRowSelected = computed(() => (grid.value.getRadioRecord && grid.value.getRadioRecord()) || (grid.value.getCheckboxRecords && grid.value.getCheckboxRecords().length > 0));
    const isEditing = computed(() => editingRow.value != null && grid.value.isActiveByRow(editingRow.value));
    var printColumns = [
      { field: 'CreatedTimeString' }, 
      { field: 'Category.Name' },
      { field: 'Title' }, 
      { field: 'PublishedString' },
      { field: 'PublishDateString' }, 
      { field: 'StartDateString' }, 
      { field: 'EndDateString' },
    ]

    const gridOptions = reactive({
      keepSource: true,
      round: true,
      border: true,
      stripe: true,
      highlightHoverColumn: true,
      highlightHoverRow: true,
      resizable: true,
      loading: false,
      customConfig: { storage: true },
      filterConfig: { remote: true },
      sortConfig: { remote: true, multiple: true },
      //editConfig: { trigger: 'manual', mode: 'row', showIcon: false, autoClear: false },
      pagerConfig: { currentPage: 1, pageSize: 10, pageSizes: [5, 10, 20], layouts: [ 'PrevJump', 'PrevPage', 'Jump', 'PageCount', 'NextPage', 'NextJump', 'Sizes', 'Total' ] },
      printConfig: { sheetName: '最新消息清單', columns: printColumns, modes: [ 'current', 'selected', 'all' ] },
      exportConfig: { filename: '最新消息清單', type: 'csv', types: [ 'html', 'csv' ], mode: 'all', modes: [ 'current', 'selected', 'all' ], columns: printColumns },
      columns: [
        { type: "checkbox", width: 35, fixed: 'left', resizable: false },
        { field: 'CreatedTimeString', title: '建立時間' },
        { field: 'Category.Name', title: '分類名稱' },
        { 
          field: "Title", title: "標題", showHeaderOverflow: true, showOverflow: true, sortable: true,
          filters: [{ checked: false, label: '包含' }],
          slots: { filter: 'name_filter' }
        },
        { field: "PublishedString", title: "發佈", showHeaderOverflow: true, showOverflow: true, resizable: false },
        { field: "PublishDateString", title: "發佈日期", showHeaderOverflow: true, showOverflow: true, resizable: false },
        { field: "StartDateString", title: "發佈開始日期", showHeaderOverflow: true, showOverflow: true, resizable: false },        
        { field: "EndDateString", title: "發佈結束日期", showHeaderOverflow: true, showOverflow: true, resizable: false },                
        { title: "操作", width: 100, fixed: 'right', align: 'center', resizable: false, slots: { default: 'operate' } },
      ],
      proxyConfig: {
        message: false,
        sort: true, 
        filter: true, 
        props: { result: 'data', total: 'totalCount' }, 
        ajax: {
          query: (params) => {    
            //params.sorts.push({ column: Column.prototype, order: 'desc', sortBy: 'Number', property:'Number' });    
            //console.log(params)
            const queryParams: { page: number, pageSize: number, keyword: string, sortings?: Sorting[], condition: Condition } = { 
              page: params.page.currentPage, 
              pageSize: params.page.pageSize, 
              keyword: keyword.value,
              sortings: params.sorts.filter(e => e.property).map(e => new Sorting(e.property, e.order === 'desc' ? SortOrder.Descending : SortOrder.Ascending)), // sorts
              condition: new Condition(customFilters.value) // custom filters
            };
            //console.log(queryParams)
            // filters
            const filters = params.filters ? params.filters.filter(e => e.values.length) : null;
            if (filters && filters.length) {
              const gridCondition = new Condition();
              // colum filters
              filters.forEach(filter => { 
                const columnCondition = new Condition();
                filter.values.forEach(subFilter => {
                  if (subFilter && subFilter instanceof Condition) {
                    const condition = subFilter as Condition;
                    if (condition.connective === LogicalConnective.And) columnCondition.and(condition);
                    else columnCondition.or(condition);
                  }
                });
                gridCondition.and(columnCondition);
              });
              queryParams.condition.and(gridCondition);
            }
            return new Promise((resolve, reject) => model?.dispatch('news/load', queryParams).then(
              payload => {
                resolve(payload)
              },
              reason => {               
                CloudFun.send('error', { subject: '讀取失敗', content: reason })
                reject(reason);
              }
            ));
          },
          delete: (params) => {
            const deleteParams = { deleteRows: params.body.removeRecords };
            return new Promise((resolve, reject) => model?.dispatch('news/save', deleteParams).then(
              payload => resolve(payload),
              reason => {
                CloudFun.send('error', { subject: '刪除失敗', content: reason })
                reject(reason);
              }
            ));
          },
          save: (params) => {
            const saveParams = { insertRows: params.body.insertRecords, updateRows: params.body.updateRecords, deleteRows: params.body.removeRecords };
            return new Promise((resolve, reject) => model?.dispatch('news/save',  saveParams).then(
              payload => { 
                editingRow.value = null;
                resolve(payload)
              },
              reason => {
                CloudFun.send('error', { subject: '保存失敗', content: reason })
                reject(reason);
              }
            ));
          }
        }
      }
    } as VxeGridProps);

    //modal 執行 新增or修改
    const submitSave = () => {
      var obj = files.value;
      const formData = new FormData();
      if(obj.length > 0){
        formData.append("file", obj[0]['file']);        
      }
      formData.append("json", JSON.stringify(form.formData));
      var method = form.model == 'insert' ? 'insert' : 'update';
      gridOptions.loading = true;
      //console.log(form.model, method)
      model?.dispatch(`news/${method}`, formData).then(
        paylod => {
          //var sorting = new Sorting('CreatedTime', 1);
          grid.value.commitProxy('query').finally(() => {  });
          CloudFun.send('info', { subject: method == 'insert' ? '新增成功' : '更新成功', content: method == 'insert' ? '最新消息新增完成' : '最新消息更新完成' })
        },
        failure => CloudFun.send('error', { subject: '操作失敗！', content: failure })
      ).finally(() => { 
        form.showEdit = false; 
        gridOptions.loading = false;
      });
    }

    const form = reactive({
      model: 'insert',   
      CityList: [],
      DistrictsList: [],
      CategoryList: [],
      selectRow: true,
      showEdit: false,
      formData: {
        Id: 0,
          CreatedTimeString: '',
          CreatedTime:'',
          Name: '',
          Published: false,
          Ordinal: 0,
          ClickCount: 0,
          Remark: '',
          Category: '',
          CategoryId: 0,
          Title: '',
          PublishDate: '',
          StartDate: '',
          EndDate: '',
          Operator: '',          
          OperatorId: '',
          Content: '',
          Picture: {
            Id: 0,
            Uri: '',
          },
          Files:[],
      },
      formRules: {
        Title: [
          { required: true, message: '請輸入標題' },          
        ],
        CategoryId: [
          { required: true, message: '請輸入分類名稱' }
        ],        
        Published: [
          { required: true, message: '請輸入發佈' }
        ],
        Content: [
          { required: true, message: '請輸入內容' }
        ],        
      } as VxeFormPropTypes.Rules,
      formItems: [
        { field: 'Title', title: '標題', span: 12, itemRender: { name: '$input', props: { placeholder: '標題' } } },
        { field: 'CategoryId', title: '分類', span: 12, itemRender: { name: '$select', options:[] } },
        { field: 'Published', title: '是否發佈', span: 12, itemRender: { name: '$radio', options: [ { label: '否', value: false }, { label: '是', value: true }] } },
        { field: 'Ordinal', title: '排序', span: 12, itemRender: { name: '$input', props: { type: 'number', placeholder: '排序' } } },
        { field: 'PublishDate', title: '發佈日期', span: 12, itemRender: { name: '$input', props: { type: 'date', placeholder: '發佈日期' } } },
        { field: 'StartDate', title: '發佈開始日期', span: 12, itemRender: { name: '$input', props: { type: 'date', placeholder: '發佈開始日期' } } },
        { field: 'EndDate', title: '發佈結束日期', span: 12, itemRender: { name: '$input', props: { type: 'date', placeholder: '發佈結束日期' } } },
        { field: 'ClickCount', title: '點擊次數', span: 12, itemRender: { name: '$input', props: { type: 'number', placeholder: '點擊次數', readonly: true } } },
        //{ field: 'Content', title: '內容', span: 24, itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, placeholder: '內容' } } },
        { span: 24, slots: { default: 'html-editer' } },
        { span: 24, slots: { default: 'file-upload' }, visible: false },
        { align: 'right', span: 24, titleAlign: 'left', itemRender: { name: '$buttons', children: [{ props: { type: 'submit', content: '確認', status: 'primary' } } , { props: { type: 'reset', content: '重置' } }] } } //, events: { click: save }
      ] as VxeFormPropTypes.Items
    });

    const baseUrl = `${process.env.VUE_APP_BACKEND_URL}`;    
    const getCategories = () => {
        return new Promise((resolve, reject) => {
          Model.createHttpClient(baseUrl).get(`${baseUrl}/api/NewsCategory`, { params: { keyword: null, orderColumn:'CreatedTime', descending: true, skipRows: null, takeRows: null, simple: true  } })
          .then(function (response: any) {
            resolve(response.payload)
          })
          .catch(function (error) {
            reject(error)
            console.log(error.response)
          });
      });
    };

    onMounted(() => {
      getCategories().then((data: any) =>{  
          var item = form.formItems[1]; 
          if (item.itemRender && data.length > 0) {
            form.CategoryList = data;
            item.itemRender.options = data;
            //console.log(item.itemRender.options, form.CategoryList[0]['value'])            
          }
      });
    })

    const onResize = () => {      
      //if(form.formData.Picture && form.formData.Picture.Uri) {
        // setTimeout(() => {
        //   console.log('test')
        //   var ins = (document.getElementsByClassName('vxe-modal--box')[0])
        //   var top = (window.screen.availHeight - ins.clientHeight) / 2;
        //   console.log(window.screen.availHeight, ins.clientHeight)
        //   console.log(top)
        //   modal.value.setPosition(top)
        // }, 200); 
      //}
    }


    const classicEditor = ClassicEditor;
    const simpleEditorConfig = {      
      extraPlugins: [
        UploadAdapterPlugin
      ],
      toolbar: {
        shouldNotGroupWhenFull: true,
        items: [
          'heading',
          '|',
          'fontFamily',
          'fontSize',
          'fontColor',
          'fontBackgroundColor',
          '|',
          'bold',
          'italic',
          'underline',
          'bulletedList',
          'numberedList',
          'outdent',
          'indent',
          'highlight',
          'insertTable',
          '|',
          'link',
          'blockQuote',
          'imageInsert',
          'mediaEmbed',
          // 'codeBlock',
          // 'htmlEmbed',
          '|',
          '|',
          'undo',
          'redo'
        ]
      },
      removePlugins: ['Markdown'],
      image: {
        toolbar: [
          'imageTextAlternative',
          'imageStyle:full',
          'imageStyle:side',
          'linkImage'
        ]
      },
      table: {
        contentToolbar: [
          'tableColumn',
          'tableRow',
          'mergeTableCells',
          'tableCellProperties',
          'tableProperties'
        ]
      },
      heading: {
          options: [
              { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
              { model: 'heading1', view: { name: 'h1', classes: 'font-bold text-xl' }, title: 'Heading 1', class: 'ck-heading_heading1' },
              { model: 'heading2', view: { name: 'h2', classes: 'font-bold text-base' }, title: 'Heading 2', class: 'ck-heading_heading2' }
          ]
      }
    };

    const UploadFiles = () => {
      var obj = AttachmentFiles.value;
      const formData = new FormData();
      if(obj.length > 0){
        var file = obj[0]['file'];
        var limit = 1024 * 1024 * 25;
        if(file['size'] >= limit) {
          CloudFun.send('error', { subject: '附件上傳失敗！', content: '檔案大小不得超過25M' })
          return;
        }
        console.log(file['name'])
        if (!/\.(gif|jpg|jpeg|png|webp|zip|rar|doc|docx|ppt|pptx|xlsx|xls|csv|txt|pdf)$/i.test(file['name'])) {
          CloudFun.send('error', { subject: '附件上傳失敗！', content: '不支援此檔案類型' })
          return;
        }
        formData.append("files", file);
      }
      
      model?.dispatch(`files/insert`, { formData: formData, id: form.formData.Id.toString() }).then(
        () => {          
          CloudFun.send('info', { subject: '新增成功', content: '新增附件完成' })
          ReloadFiles()
        },
        failure => CloudFun.send('error', { subject: '操作失敗！', content: failure })
      ).finally(() => {
      });
    }

    const ReloadFiles = () => {
      model?.dispatch('news/find', form.formData.Id).then(
        paylod => {
          AttachmentFiles.value = []
          if(paylod.Files && paylod.Files.length > 0) { 
            form.formData.Files = paylod.Files;
          } else { form.formData.Files = [] }
        },
        failure => CloudFun.send('error', { subject: '附件讀取失敗', content: failure.message })
      );
    }

    
    
    return {    
      UploadFiles,  
      classicEditor,
      simpleEditorConfig,
      form,
      grid,
      gridOptions,
      loading,
      isEditing,
      editingRow,
      isRowSelected,
      keyword,
      customFilters,
      submitSave,
      getCategories,
      files,
      modal,
      uploader,
      onResize,
      oriUri,
      AttachmentUploader,
      AttachmentFiles,
      ReloadFiles
    };
  },
  methods: {
    reset() { 
      this.form.formData.Id = 0
      this.form.formData.CreatedTimeString = ''
      this.form.formData.CreatedTime = ''
      this.form.formData.Title = ''
      this.form.formData.Published = false
      this.form.formData.Ordinal = 0
      this.form.formData.ClickCount = 0
      this.form.formData.Remark = ''
      this.form.formData.Category = ''
      this.form.formData.CategoryId = this.form.CategoryList.length > 0 ? this.form.CategoryList[0]['value'] : 0;
      this.form.formData.Title = ''
      this.form.formData.PublishDate = ''
      this.form.formData.StartDate = ''
      this.form.formData.EndDate = ''
      this.form.formData.Operator = ''
      this.form.formData.OperatorId = ''
      this.form.formData.Content = ''  
      this.form.formData.Files = []
      if(this.files.length > 0) this.files = []
      if(this.AttachmentFiles.length > 0) this.AttachmentFiles = []
      this.form.formData.Picture = { Uri:'', Id: 0, }  
    },
    edit(row: any) {
      if(this.files.length > 0) this.files = []
      if(this.AttachmentFiles.length > 0) this.AttachmentFiles = []
      this.$model.dispatch('news/find', row.Id).then(
        paylod => {
          Object.assign(this.form.formData, paylod)
          if(this.form.formData.Picture.Uri) { 
            setTimeout(() => {
              this.oriUri = this.form.formData.Picture.Uri;
              this.form.formData.Picture.Uri = this.form.formData.Picture.Uri + "?" + new Date().getTime(); 
            }, 200);
          } else { this.oriUri = '' }
          this.form.model = 'edit';
          this.form.showEdit = true;
          var item = this.form.formItems[9]
          if(item) item.visible = true;
          //console.log(paylod)
        },
        failure => this.$send('error', { subject: '操作失敗！', content: failure.message })
      );
    },
    addNewRow() {
      this.reset();
      this.form.model = 'insert';
      this.form.showEdit = true;
      var item = this.form.formItems[9]
      if(item) item.visible = false;
    },
    save(row?: any) {
      const { insertRecords, updateRecords, removeRecords } = this.grid.getRecordset();
      let needSave = insertRecords.length || updateRecords.length || removeRecords.length;
      needSave &&= row && (insertRecords.some(e => e._XID === row._XID) || updateRecords.some(e => e._XID === row._XID) || removeRecords.some(e => e._XID === row._XID));   
      const isRowEditing = row && this.editingRow === row;
      if (needSave) {
        this.grid.commitProxy('save').then(
          () => {
            if (isRowEditing && this.editingRow === row) this.edit(row);
            else this.editingRow = null;
          }
        );
      } else if (!row || row === this.editingRow) this.editingRow = null;
    },
    remove(row: any) {
      if (row && confirm("確定要刪除此筆資料嗎?")) {
        this.gridOptions.loading = true;
        this.$model.dispatch('news/delete', row.Id).then(
          () => { this.grid.commitProxy('query').finally(() => { this.gridOptions.loading = false; }); },
          failure => this.$send('error', { subject: '最新消息刪除失敗', content: failure.message })
        );
      }
    },
    removeSelectedRows() {
      cash('#batch-dropdown').dropdown('hide');
      const rows = this.grid.getCheckboxRecords();
      if (rows && rows.length && confirm("確定要刪除已被選擇的資料嗎?")) {
        this.grid.commitProxy('delete');
      }
    },
    changeFilterEvent(event: Event, option: VxeColumnPropTypes.Filter, panel: any, toogleChecked?: boolean) {
      if (toogleChecked) option.checked = !option.checked;
      if (option.checked && option.data) {
        if (!option.value) option.value = new Condition('Name', Operator.Contains);
        option.value.value = option.data;
      } else option.value = option.data = undefined;
      if (toogleChecked || option.checked) panel.changeOption(event, !!option.data, option)
    },    
    async onExport(type: 'csv' | 'html', mode: 'current' | 'selected' | 'all' = 'all') {
      const config: any = { ...this.gridOptions.exportConfig, type, mode };
      if (mode === 'all') config.data = await this.$model.dispatch('news/query');
      console.log(config)
      this.grid.exportData({ ...config, type, mode });
    },
    async onPrint(mode: 'current' | 'selected' | 'all' = 'all') {
      const config: any = { ...this.gridOptions.printConfig, mode };
      if (mode === 'all') config.data = await this.$model.dispatch('news/query');
      this.grid.print(config);
    },
    inputFile(newFile: any, oldFile: any, prevent: any) {      
      
      if (newFile && !oldFile) {
        this.$nextTick(function () {          
        })
      }
      if (!newFile && oldFile) {        
      }
    },
    inputFilter(newFile: any, oldFile: any, prevent: any) {
      if (newFile && !oldFile) {
        if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
          //this.alert('Your choice is not a picture')
          return prevent()
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = ''
        const URL = window.URL || window.webkitURL        
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file)
          this.form.formData.Picture.Uri = newFile.url
          //setTimeout(() => {
            // var ins = (document.getElementsByClassName('vxe-modal--box')[0])
            // var top = (window.screen.availHeight - ins.clientHeight) / 2;
            // console.log(top)
            // this.modal.setPosition(top)
          //}, 200);
          
        }
      }
    },
    deletePicture() {
      if(this.form.formData.Picture.Id == 0) {
        //CloudFun.send('error', { subject: '操作失敗！', content: '請確認圖片是否存在！' })
        if(this.files.length > 0) this.removeFile()
        return;
      }
      this.$model.dispatch(`picture/deletePicture`, this.form.formData.Picture.Id).then(
        paylod => {
          CloudFun.send('info', { subject: '刪除成功！', content: '圖片刪除成功' })
          this.removeFile()
        },
        failure => CloudFun.send('error', { subject: '操作失敗！', content: failure})
      ).finally(() => {
      });      
    },
    removeFile() {
      console.log(this.files.length > 0)
      if(this.files.length > 0) this.files = [];
      this.form.formData.Picture.Uri = '';
    },
    deleteFile(id: any) {
      this.$model.dispatch(`files/delete`, id).then(
        () => {
          CloudFun.send('info', { subject: '刪除成功！', content: '附件刪除成功' })
          this.ReloadFiles()
        },
        failure => CloudFun.send('error', { subject: '附件刪除失敗！', content: failure})
      ).finally(() => {
      });
    },
    downloadFile(id: any) {
      this.$model.dispatch(`files/download`, id).then(
        (data) => {
          if(data) window.open(data, '_blank');
        },
        failure => CloudFun.send('error', { subject: '附件下載失敗！', content: failure})
      ).finally(() => {
      });
    },
  }
});
