<template>
  <fieldset :read-only="readOnly">
    <template v-if="isGroupedItems">
      <div
        v-for="(groupItem, index) in items"
        :key="`CheckBoxListItem-${index}`"
      >
        <label class="group">
          <input
            type="checkbox"
            @click="onSelectAllButtonClick(groupItem.name)"
            :checked="
              itemCount[groupItem.name] == selectedCount[groupItem.name]
            "
            :disabled="readOnly"
          />
          &nbsp;{{ groupItem.caption || groupItem.name }}
        </label>
        <ul class="group">
          <li
            v-for="(item, index) in groupItem.items"
            :key="`CheckBoxListGroupItem-${index}`"
            :style="{ width: itemWidthPercentage }"
            :name="name"
          >
            <div>
              <input
                :id="`CheckBoxList-${id}-${item.value}`"
                type="checkbox"
                :value="item.value"
                v-model="selectedValues"
                @change="onChange()"
                :disabled="readOnly"
              />
              <label :for="`CheckBoxList-${id}-${item.value}`">{{
                item.name
              }}</label>
            </div>
          </li>
        </ul>
      </div>
    </template>
    <ul v-else>
      <li
        v-for="(item, index) in items"
        :key="`CheckBoxListItem-${index}`"
        :style="{ width: itemWidthPercentage }"
        :name="name"
      >
        <div>
          <input
            :id="`CheckBoxList-${id}-${item.value}`"
            type="checkbox"
            :value="item.value"
            v-model="selectedValues"
            @change="onChange()"
            :disabled="readOnly"
          />
          <label :for="`CheckBoxList-${id}-${item.value}`">{{
            item.name
          }}</label>
        </div>
      </li>
    </ul>
    <label class="group">
      <input
        type="checkbox"
        @click="onSelectAllButtonClick('all')"
        :checked="itemCount['all'] == selectedCount['all']"
        :disabled="readOnly"
      />
      &nbsp;Select All</label
    >
  </fieldset>
</template>

<style scoped>
fieldset {
  padding: 10px;
  border: 1px #d7d7d7 solid;
  border-radius: 4px;
  position: relative;
}
fieldset[read-only="true"] {
  border: 1px #ccc dashed;
}
ul {
  margin: 0;
  padding: 0;
  margin-bottom: 6px;
  width: 100%;
  list-style-type: none;
  display: table;
  table-layout: fixed;
}
ul.group {
  padding: 2px;
  border-radius: 4px;
  border: 1px #d7d7d7 solid;
}
ul li {
  margin: 0;
  padding: 0;
  float: left;
  display: table-cell;
}
ul li div {
  margin: 2px;
}
ul li label {
  margin: 0;
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: white;
  text-align: center;
  width: 100%;
  display: inline-block;
}
ul li label[read-only="true"] {
  border: 1px dashed #ccc;
}
ul li input[type="checkbox"] {
  opacity: 0;
  width: 1px;
  height: 1px;
  display: none;
}
ul li input[type="checkbox"]:checked ~ label {
  background: dodgerBlue;
  color: white;
}
label.group {
  margin: 0;
  padding: 5px 10px;
  border: 1px #d7d7d7 solid;
  border-radius: 4px;
  background-color: #d7d7d741;
  align-items: center;
  justify-content: center;
  font-size: smaller;
  float: right;
}
</style>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from "@cloudfun/core";
import { v1 as uuid } from "uuid";

export default defineComponent({
  props: {
    name: String,
    modelValue: { type: Array as PropType<any[]>, default: [] as any[] },
    cell: Object,
    columnCount: { type: Number, default: 0 },
    readOnly: Boolean,
    items: { type: Array as PropType<any[]>, default: [] as any[] },
  },
  setup(props) {
    const selectedValues = ref<any[]>([]);
    const itemCount = ref<any>({ all: 0 });
    const selectedCount = ref<any>({ all: 0 });

    const itemWidthPercentage = computed(() =>
      props.columnCount > 0 ? `${100 / props.columnCount}%` : "auto"
    );
    const isGroupedItems = computed(() => {
      let result = false;
      props.items.forEach((item) => {
        if (!result && item.items && Array.isArray(item.items)) {
          result = true;
        }
      });
      return result;
    });

    return {
      selectedValues,
      itemCount,
      selectedCount,
      id: uuid(),
      itemWidthPercentage,
      isGroupedItems,
    };
  },
  methods: {
    onSelectAllButtonClick(name: string) {
      const groupItem = this.items.find((e) => e.name === name);
      let items = null;
      if (groupItem == null) {
        items = this.items;
        if (this.selectedValues.length === this.itemCount[name]) {
          this.selectedValues = [];
        } else {
          items.forEach((item) => {
            if (item.items && Array.isArray(item.items)) {
              item.items.forEach((subItems: any) => {
                if (!this.selectedValues.includes(subItems.value))
                  this.selectedValues.push(subItems.value);
              });
            } else if (!this.selectedValues.includes(item.value))
              this.selectedValues.push(item.value);
          });
        }
      } else {
        items = groupItem.items;
        var allSelected = true;
        items.forEach((item: any) => {
          allSelected &&= this.selectedValues.includes(item.value);
        });
        if (allSelected) {
          var values = groupItem.items.map((e: any) => e.value);
          this.selectedValues = this.selectedValues.filter(
            (e) => !values.includes(e)
          );
        } else {
          items.forEach((item: any) => {
            if (!this.selectedValues.includes(item.value))
              this.selectedValues.push(item.value);
          });
        }
      }
      this.onChange();
    },
    hasGroupItem() {
      return this.items.find((e) => e.items && Array.isArray(e.items)) != null;
    },
    onChange(e?: Event, silent?: boolean) {
      this.selectedCount = { all: 0 };
      if (this.hasGroupItem()) {
        this.selectedValues.forEach((value) => {
          this.items.forEach((item) => {
            if (item.items.find((e: any) => e.value === value)) {
              this.selectedCount[item.name] =
                (this.selectedCount[item.name] || 0) + 1;
            }
          });
          this.selectedCount.all++;
        });
      } else {
        this.selectedCount.all = this.selectedValues.length;
      }
      if (!silent) {
        this.$emit("update:modelValue", this.selectedValues);
        this.$emit("change", this.selectedValues);
        if (this.cell) this.cell.setValue(this.selectedValues);
      }
    },
  },
  created() {
    this.items.forEach((item) => {
      if (item.items && Array.isArray(item.items)) {
        this.itemCount[item.name] = item.items.length;
        this.itemCount.all += item.items.length;
      } else this.itemCount.all++;
    });
    this.selectedValues = [...this.modelValue];
    this.onChange(undefined, true);
  },
  watch: {
    items() {
      this.itemCount.all = 0;
      this.items.forEach((item) => {
        if (item.items && Array.isArray(item.items)) {
          this.itemCount[item.name] = item.items.length;
          this.itemCount.all += item.items.length;
        } else this.itemCount.all++;
      });
      this.onChange(undefined, true);
    },
    modelValue(current) {
      this.selectedValues = [...current];
      this.onChange(undefined, true);
    },
  },
});
</script>
