import { ISitemapNode } from "@cloudfun/core";

const sitemap: ISitemapNode = {
  icon: "HomeIcon",
  to: '',
  title: "Home",  
  subNodes: [
    {
      icon: "HomeIcon",
      to: 'dashboard',
      title: "Dashboard",
    },    
    {
      icon: "SettingsIcon",
      to: 'configuration',
      title: "系統配置",
    },
    {
      icon: "FileTextIcon",
      to: 'organization',
      title: "關於我們",
      subNodes: [
        {
          icon: "FileTextIcon",
          to: 'organization',
          title: "組織架構",
        },
        {
          icon: "FileTextIcon",
          to: 'task',
          title: "成立宗旨及任務",
        },
        {
          icon: "FileTextIcon",
          to: 'privacy',
          title: "隱私權政策",
        },
      ]
    },
    {
      icon: "UserIcon",
      to: 'user',
      title: "用戶管理",
      subNodes: [
        {
          icon: "UsersIcon",
          to: 'role',
          title: "角色管理",
        },
        {
          icon: "UserIcon",
          to: 'user',
          title: "用戶資訊",
        }        
      ]
    },
    {
      icon: "ActivityIcon",
      to: 'action-log',
      title: "操作紀錄",
    },
    {
      icon: "FlagIcon",
      to: 'banner',
      title: "廣告管理",
    },
    {
      icon: "AwardIcon",
      to: 'guild',
      title: "公會管理",
    },
    {
      icon: "UserCheckIcon",
      to: 'member',
      title: "會員管理",
    },
    {
      icon: "ImageIcon",
      to: 'album',
      title: "相簿管理",
    },
    {
      icon: "MailIcon",
      to: 'news',
      title: "最新消息管理",
      subNodes: [
        {
          icon: "GitMergeIcon",
          to: 'news-category',
          title: "分類管理",
        },
        {
          icon: "FileTextIcon",
          to: 'news',
          title: "內容資訊",
        }        
      ]
    },
    {
      icon: "AnchorIcon",
      to: 'link',
      title: "相關連結管理",
      subNodes: [
        {
          icon: "GitMergeIcon",
          to: 'link-category',
          title: "分類管理",
        },
        {
          icon: "FileTextIcon",
          to: 'link',
          title: "內容資訊",
        }        
      ]
    },
    "devider",
    // {
    //   icon: "BoxIcon",
    //   title: "Menu Layout",
    //   subNodes: [
    //     {
    //       icon: "",
    //       to: '/dashboard',
    //       title: "Side Menu",
    //       ignore: true,
    //     },
    //     {
    //       icon: "",
    //       to: "/simple-menu/dashboard",
    //       title: "Simple Menu",
    //       ignore: true,
    //     },
    //     {
    //       icon: "",
    //       to: "/top-menu/dashboard",
    //       title: "Top Menu",
    //       ignore: true,
    //     },
    //   ],
    // },
    // {
    //   icon: "InboxIcon",
    //   to: "inbox",
    //   title: "Inbox",
    // },
    // {
    //   icon: "HardDriveIcon",
    //   to: "file-manager",
    //   title: "File Manager",
    // },
    // {
    //   icon: "CreditCardIcon",
    //   to: "point-of-sale",
    //   title: "Point of Sale",
    // },
    // {
    //   icon: "MessageSquareIcon",
    //   to: "chat",
    //   title: "Chat",
    // },
    // {
    //   icon: "FileTextIcon",
    //   to: "post",
    //   title: "Post",
    // },
    // "devider",
    // {
    //   icon: "EditIcon",
    //   to: "crud-data-list",
    //   title: "Crud",
    //   subNodes: [
    //     {
    //       icon: "",
    //       to: "crud-data-list",
    //       title: "Data List",
    //     },
    //     {
    //       icon: "",
    //       to: "crud-form",
    //       title: "Form",
    //     },
    //   ],
    // },
    // {
    //   icon: "UsersIcon",
    //   to: "users-layout-1",
    //   title: "Users",
    //   subNodes: [
    //     {
    //       icon: "",
    //       to: "users-layout-1",
    //       title: "Layout 1",
    //     },
    //     {
    //       icon: "",
    //       to: "users-layout-2",
    //       title: "Layout 2",
    //     },
    //     {
    //       icon: "",
    //       to: "users-layout-3",
    //       title: "Layout 3",
    //     },
    //   ],
    // },
    // {
    //   icon: "TrelloIcon",
    //   to: "profile-overview-1",
    //   title: "Profile",
    //   subNodes: [
    //     {
    //       icon: "",
    //       to: "profile-overview-1",
    //       title: "Overview 1",
    //     },
    //     {
    //       icon: "",
    //       to: "profile-overview-2",
    //       title: "Overview 2",
    //     },
    //     {
    //       icon: "",
    //       to: "profile-overview-3",
    //       title: "Overview 3",
    //     },
    //   ],
    // },
    // {
    //   icon: "LayoutIcon",
    //   to: "wizard-layout-1",
    //   title: "Pages",
    //   subNodes: [
    //     {
    //       icon: "",
    //       to: "wizard-layout-1",
    //       title: "Wizards",
    //       subNodes: [
    //         {
    //           icon: "",
    //           to: "wizard-layout-1",
    //           title: "Layout 1",
    //         },
    //         {
    //           icon: "",
    //           to: "wizard-layout-2",
    //           title: "Layout 2",
    //         },
    //         {
    //           icon: "",
    //           to: "wizard-layout-3",
    //           title: "Layout 3",
    //         },
    //       ],
    //     },
    //     {
    //       icon: "",
    //       to: "blog-layout-1",
    //       title: "Blog",
    //       subNodes: [
    //         {
    //           icon: "",
    //           to: "blog-layout-1",
    //           title: "Layout 1",
    //         },
    //         {
    //           icon: "",
    //           to: "blog-layout-2",
    //           title: "Layout 2",
    //         },
    //         {
    //           icon: "",
    //           to: "blog-layout-3",
    //           title: "Layout 3",
    //         },
    //       ],
    //     },
    //     {
    //       icon: "",
    //       to: "pricing-layout-1",
    //       title: "Pricing",
    //       subNodes: [
    //         {
    //           icon: "",
    //           to: "pricing-layout-1",
    //           title: "Layout 1",
    //         },
    //         {
    //           icon: "",
    //           to: "pricing-layout-2",
    //           title: "Layout 2",
    //         },
    //       ],
    //     },
    //     {
    //       icon: "",
    //       to: "invoice-layout-1",
    //       title: "Invoice",
    //       subNodes: [
    //         {
    //           icon: "",
    //           to: "invoice-layout-1",
    //           title: "Layout 1",
    //         },
    //         {
    //           icon: "",
    //           to: "invoice-layout-2",
    //           title: "Layout 2",
    //         },
    //       ],
    //     },
    //     {
    //       icon: "",
    //       to: "faq-layout-1",
    //       title: "FAQ",
    //       subNodes: [
    //         {
    //           icon: "",
    //           to: "faq-layout-1",
    //           title: "Layout 1",
    //         },
    //         {
    //           icon: "",
    //           to: "faq-layout-2",
    //           title: "Layout 2",
    //         },
    //         {
    //           icon: "",
    //           to: "faq-layout-3",
    //           title: "Layout 3",
    //         },
    //       ],
    //     },
    //     {
    //       icon: "",
    //       to: "login",
    //       title: "Login",
    //     },
    //     {
    //       icon: "",
    //       to: "register",
    //       title: "Register",
    //     },
    //     {
    //       icon: "",
    //       to: "error-page",
    //       title: "Error Page",
    //     },
    //     {
    //       icon: "",
    //       to: "update-profile",
    //       title: "Update profile",
    //     },
    //     {
    //       icon: "",
    //       to: "change-password",
    //       title: "Change Password",
    //     },
    //   ],
    // },
    // "devider",
    // {
    //   icon: "InboxIcon",
    //   to: "regular-table",
    //   title: "Components",
    //   subNodes: [
    //     {
    //       icon: "",
    //       to: "regular-table",
    //       title: "Table",
    //       subNodes: [
    //         {
    //           icon: "",
    //           to: "regular-table",
    //           title: "Regular Table",
    //         },
    //         {
    //           icon: "",
    //           to: "tabulator",
    //           title: "Tabulator",
    //         },
    //       ],
    //     },
    //     {
    //       icon: "",
    //       to: "accordion",
    //       title: "Accordion",
    //     },
    //     {
    //       icon: "",
    //       to: "button",
    //       title: "Button",
    //     },
    //     {
    //       icon: "",
    //       to: "modal",
    //       title: "Modal",
    //     },
    //     {
    //       icon: "",
    //       to: "alert",
    //       title: "Alert",
    //     },
    //     {
    //       icon: "",
    //       to: "progress-bar",
    //       title: "Progress Bar",
    //     },
    //     {
    //       icon: "",
    //       to: "tooltip",
    //       title: "Tooltip",
    //     },
    //     {
    //       icon: "",
    //       to: "dropdown",
    //       title: "Dropdown",
    //     },
    //     {
    //       icon: "",
    //       to: "toast",
    //       title: "Toast",
    //     },
    //     {
    //       icon: "",
    //       to: "typography",
    //       title: "Typography",
    //     },
    //     {
    //       icon: "",
    //       to: "icon",
    //       title: "Icon",
    //     },
    //     {
    //       icon: "",
    //       to: "loading-icon",
    //       title: "Loading Icon",
    //     },
    //   ],
    // },
    // {
    //   icon: "SidebarIcon",
    //   to: "regular-form",
    //   title: "Forms",
    //   subNodes: [
    //     {
    //       icon: "",
    //       to: "regular-form",
    //       title: "Regular Form",
    //     },
    //     {
    //       icon: "",
    //       to: "datepicker",
    //       title: "Datepicker",
    //     },
    //     {
    //       icon: "",
    //       to: "tail-select",
    //       title: "Tail Select",
    //     },
    //     {
    //       icon: "",
    //       to: "file-upload",
    //       title: "File Upload",
    //     },
        // {
        //   icon: "",
        //   to: "wysiwyg-editor",
        //   title: "Wysiwyg Editor",
        // },
    //     {
    //       icon: "",
    //       to: "validation",
    //       title: "Validation",
    //     },
    //   ],
    // },
    // {
    //   icon: "HardDriveIcon",
    //   to: "chart",
    //   title: "Widgets",
    //   subNodes: [
    //     {
    //       icon: "",
    //       to: "chart",
    //       title: "Chart",
    //     },
    //     {
    //       icon: "",
    //       to: "slider",
    //       title: "Slider",
    //     },
    //     {
    //       icon: "",
    //       to: "image-zoom",
    //       title: "Image Zoom",
    //     },
    //   ],
    // },
  ]
};

export default sitemap;